export function playVideo() {
    const playButtons = document.querySelectorAll('.video-play-button');
    playButtons.forEach(button => {
        button.addEventListener('click', function() {
            const videoValue = button.getAttribute('video-value');
            const videoSection = document.getElementById(videoValue);
            const gradient = videoSection.querySelector('.full-video-gradient-cover');
            const imageCover = videoSection.querySelector('.full-video-image-cover');
            const video = videoSection.querySelector('.full-video');

            gradient.style.display = 'none';
            imageCover.style.display = 'none';
            button.style.display = 'none';
            video.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
            video.play();
        });
    })
}