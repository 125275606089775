import Cookies from "js-cookie";
import gsap from "gsap";

export function initModals() {
  let currentlyShowingPopup = false;
  let cookieClickedAwayExit = false;

  const exitModal = document.getElementById("exit-modal-popup");

  if (exitModal) {
    if (Cookies.get("exitPopup") === undefined) {
      document.body.addEventListener("mouseleave", () => {
        if (!currentlyShowingPopup && !cookieClickedAwayExit) {
          exitModal.showModal();
          currentlyShowingPopup = true;
        }
      });
    }
  }

  document.querySelectorAll(".close-exit-popup").forEach((button) => {
    button.addEventListener("click", () => {
      exitModal.close();
      Cookies.set("exitPopup", "shown", { expires: 7 });
      currentlyShowingPopup = false;
      cookieClickedAwayExit = true;
    });
  });

  function openVideoModal(modalid) {
    const dialog = document.getElementById(modalid);
    const video = dialog.querySelector("video");
    dialog.showModal();
    video.play();
    gsap.to(dialog, {
      opacity: 1,
      duration: 0.5
    });
  }

  function closeVideoModal(modalid) {
    const dialog = document.getElementById(modalid);
    const video = dialog.querySelector("video");
    gsap.to(dialog, {
      opacity: 0,
      duration: 0.25,
      onComplete: () => {
        dialog.close();
        video.pause();
        video.currentTime = 0;
      }
    });
  }

  window.openVideoModal = openVideoModal;
  window.closeVideoModal = closeVideoModal;
}
