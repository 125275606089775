import AOS from "aos";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function initAnimations() {
  AOS.init({ startEvent: "load" });

  const globalCounter = document.querySelectorAll(
    ".counter-selector-animation"
  );

  //check if globalCounter exists
  if (globalCounter.length > 0) {
    globalCounter.forEach((counter) => {
      gsap.from(counter, {
        textContent: 0,
        duration: 1,
        snap: { textContent: 1 },
        scrollTrigger: {
          toggleActions: "restart none reverse none",
          trigger: counter,
          start: "top 80%",
          end: "bottom 100%"
        },
        onComplete: () => {
          counter.innerHTML = counter.innerHTML + "+";
        }
      });
    });
  }


  let resizeTimer;
  window.addEventListener("resize", () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      ScrollTrigger.refresh(); // Call refresh only after the resize event is stable
    }, 200);
  });

  const wordsElement = document.querySelector(".is-changing-words");
  console.log(wordsElement);

  if (wordsElement) {
    // Split the text content into an array and trim whitespace
    let words = wordsElement.textContent.split(",").map((word) => word.trim());

    // Clear the original content
    wordsElement.textContent = "";

    // Generate HTML for each word and set it inside the element
    wordsElement.innerHTML = words
      .map((word) => `<span>${word}</span>`)
      .join("");

    // Calculate the width based on the longest word
    let largestWordLength = Math.max(...words.map((word) => word.length));
    // wordsElement.style.width = `${largestWordLength}ch`;
    wordsElement.style.width = "fit-content";

    let animation = gsap.timeline({ repeat: 20 });
    let targets = document.querySelectorAll(".is-changing-words span");
    let numberOfTargets = targets.length;

    let duration = 0.8; //change this
    let pause = 1.25; // change this

    let stagger = duration + pause;
    let repeatDelay = stagger * (numberOfTargets - 1) + pause;

    gsap.set(".is-changing-words", { autoAlpha: 1 });
    animation
      .from(targets, {
        y: 50,
        duration: duration,
        opacity: 0,
        stagger: {
          each: stagger,
          repeat: -1,
          repeatDelay: repeatDelay
        }
      })
      .to(
        targets,
        {
          y: -50,
          duration: duration,
          opacity: 0,
          stagger: {
            each: stagger,
            repeat: -1,
            repeatDelay: repeatDelay
          }
        },
        stagger
      );
  }
}
