import "./styles/main.scss";
console.log("Hello, Vite!");
import AOS from "aos";
import "aos/dist/aos.css";
import { generalSettings } from "./scripts/general.js";

import { initModals } from "./scripts/modals.js";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger.js";
import { initializeSplide } from "./scripts/splide.js";
import { initLazyLoad } from "./scripts/lazyload.js";
import { initAnimations } from "./scripts/animations.js";
import { initForms } from "./scripts/forms.js";
import { playVideo } from "./scripts/playvideo.js";
// import { initBento } from "./scripts/bento.js";
import { handleResize } from "./scripts/resize.js";

initializeSplide();
gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", () => {
  generalSettings(); // General Settings
  initLazyLoad(); // Initialize Lazy Loading
  // initBento(); // Initialize BentoGrid
  initForms(); // Initialize Form Validations
  initModals(); // Initialize Modals and Popups
  initAnimations();
  playVideo();
  //add here a function where every a tag with href starting with 'https://calendly.com/' is opened in a new tab
  const links = document.querySelectorAll("a");
  links.forEach((link) => {
    if (link.href.startsWith("https://calendly.com/")) {
      link.setAttribute("target", "_blank");
    }
  });
});

window.addEventListener("load", () => {
  AOS.refresh();
  setTimeout(() => {
    AOS.refresh();
  }, 1000);
});

window.addEventListener("resize", handleResize);
