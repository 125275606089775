import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import gsap from "gsap";

export function initializeSplide() {
  document.addEventListener("DOMContentLoaded", function () {
    var splideTestiElement = document.querySelector(".splide-testimonials");
    var splideReferenzenElement2 = document.querySelector(".splide.referenzen");
    var splideBilderElement = document.querySelector(".splide.bilder");
    //var splideLogosHeader = document.querySelector(".splide-logos-header")
    var splideLogosHeader = document.querySelectorAll(".splide-logos-header");
    var themenSplide = document.querySelectorAll(".projekt-themen-container");
    var listenElemente = document.querySelectorAll(".splide-items-liste");

    var primarySlider = document.getElementById("primary_slider");

    if (splideTestiElement) {
      var splideTesti = new Splide(splideTestiElement, {
        autoScroll: {
          speed: 0.4
        },
        type: "loop",
        drag: "free",
        arrows: false,
        focus: "center",
        padding: "0",
        perPage: 3,
        perMove: 1,
        gap: "0",
        pagination: false,
        breakpoints: {
          1400: {
            perPage: 2
          },
          768: {
            perPage: 1,
            gap: "1rem",
            padding: 0
          }
        }
      });
      splideTesti.mount({ AutoScroll });
    }

    if (splideLogosHeader.length > 0) {
      splideLogosHeader.forEach((element) => {
        var splideTesti = new Splide(element, {
          autoScroll: {
            speed: 0.4
          },
          type: "loop",
          drag: "free",
          arrows: false,
          focus: "center",
          perPage: 10,
          perMove: 1,
          autoWidth: true,
          gap: "2rem",
          pagination: false,
          breakpoints: {
            1024: {
              perPage: 2,
              gap: "1rem"
            }
          }
        });
        splideTesti.mount({ AutoScroll });
      });
    }

    if (splideReferenzenElement2) {
      var splideReferenzen = new Splide(splideReferenzenElement2, {
        type: "loop",
        // autoplay: true,
        drag: false,
        perPage: 1,
        perMove: 1,
        arrows: false,
        gap: "1rem",
        pagination: false
      });
      splideReferenzen.mount();

      var forwardButton = document.querySelector(".splide-left");
      var backwardButton = document.querySelector(".splide-right");

      forwardButton.addEventListener("click", function () {
        splideReferenzen.go("<");
      });

      backwardButton.addEventListener("click", function () {
        splideReferenzen.go(">");
      });

      //watch when splide is moved
      splideReferenzen.on("move", function () {
        const counterReferenzen = splideReferenzenElement2.querySelectorAll(
          ".ref-number.counter-selector-animation"
        );

        counterReferenzen.forEach((counter) => {
          gsap.from(counter, {
            textContent: 0,
            duration: 1,
            snap: { textContent: 1 },
            scrollTrigger: {
              toggleActions: "restart none reverse none",
              trigger: counter,
              start: "top 80%",
              end: "bottom 100%"
            },
            onComplete: () => {
              counter.innerHTML = counter.innerHTML + "+";
            }
          });
        });
      });
    }

    if (splideBilderElement) {
      var splideBilder = new Splide(".splide.bilder", {
        type: "loop",
        drag: false,
        perPage: 1,
        perMove: 1,
        arrows: false,
        gap: "2rem",
        pagination: false
      });

      splideBilder.mount();
    }

    if (primarySlider) {
      var primarySlider1 = new Splide("#primary_slider", {
        type: "fade",
        drag: false,
        pagination: false,
        padding: "0.5rem",
        autoScroll: {
          speed: 0.4
        },
        padding: {
          left: "2rem",
          right: "2rem"
        },
        focus: "center",
        autoplay: true,
        arrows: false,
      });

      // Thumbnails slider.
      var thumbnailSlider = new Splide("#thumbnail_slider", {
        type: "loop",
        drag: false,
        autoScroll: {
          speed: 0.4
        },
        perPage: 3,
        autoplay: true,
        padding: {
          left: "20vw",
          right: "20vw"
        },
        // isNavigation: true,
        gap: 0,
        focus: "center",
        pagination: false,
        arrows: false,
        breakpoints: {
          1024: {
            perPage: 2
          },
          768: {
            perPage: 1,
            padding: {
              left: "20vw",
              right: "20vw"
            }
          }
        }
      }).mount();

      primarySlider1.sync(thumbnailSlider).mount({ AutoScroll });
    }

    if (themenSplide.length > 0) {
      themenSplide.forEach((element) => {
        var splideThemen = element.querySelector(".projekt-themen");
        var splideThemenMount = new Splide(splideThemen, {
          type: "loop",
          drag: false,
          perPage: 4,
          perMove: 1,
          start: 0,
          arrows: false,
          gap: "0rem",
          pagination: false,
          breakpoints: {
            1024: {
              perPage: 2,
              gap: "0rem"
            },
            768: {
              perPage: 1,
              gap: "0rem"
            }
          }
        });
        splideThemenMount.mount();
        var forwardButton = element.querySelector(".increase-one");
        forwardButton.addEventListener("click", function () {
          splideThemenMount.go(">");
        });
      });
    }

    if (listenElemente.length > 0) {
      listenElemente.forEach((element) => {
        var splideListen = new Splide(element, {
          autoScroll: {
            speed: 0.25
          },
          type: "loop",
          perPage: 9,
          perMove: 1,
          autoWidth: true,
          arrows: false,
          gap: "1rem",
          pagination: false,
          breakpoints: {
            1024: {
              perPage: 2
            },
            768: {
              perPage: 1
            }
          }
        });
        splideListen.mount({ AutoScroll });
      });
    }
  });
}
