export function initForms() {
    const forms = document.querySelectorAll(".needs-validation");

    forms.forEach((form) => {
        form.addEventListener("submit", function (event) {
            if (!form.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();
                handleFormValidation(form);
            }
        }, false);
    });
}

function handleFormValidation(form) {
    const inputs = form.querySelectorAll(".form-control-custom, .form-check-input");

    inputs.forEach((input) => {
        if (input.checkValidity() === false) {
            input.classList.add("is-invalid");
            console.log("Input validation failed:", input);
        } else {
            input.classList.add("is-valid");
        }
    });
}
