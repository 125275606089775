export function handleResize() {
    const containerWrapper = document.querySelector(".container").offsetLeft;
    if (window.innerWidth < 1280) {
        document.querySelectorAll(".custom-container-left-padding").forEach(el => {
            el.style.paddingLeft = "0px";
            el.style.opacity = 1;
        });
    } else {
        document.querySelectorAll(".custom-container-left-padding").forEach(el => {
            el.style.paddingLeft = containerWrapper + "px";
            el.style.opacity = 1;
        });
    }
    
}
